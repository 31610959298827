:root {
  /* fonts */
  --font-made: Made, Helvetica, Arial, sans-serif;
  --font-neue: Neue, Helvetica, Arial, sans-serif;

  /* colors */
  --green: #0ca34d;
  --deep-green: #0da34e;
  --black: #010101;
  --white: #ffffff;
  --body-black: #151515;
  --light-grey: #b7b7b7;
  --blue: #4e5cff;
}

::selection {
  color: var(--white);
  background: var(--black);
}

* {
  transform: translate3d(0px, 0px, 0rem) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}
